import {
  ActionResponse,
  DeliveryProfile,
  Product,
  UploadPhotoResponse,
  EReadStoreBy,
  EReadEntityBy,
} from '../../types';
import { ProductFilterInputParams } from '../../types/product';
import axios from 'axios';
import { getBuyerProductEndpoint } from './ProductRepository.util';
import { transformSlug } from 'utils/convert';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';

const API_STORE = process.env.NEXT_PUBLIC_API_STORE;

export const requestGetProducts = async (
  storeId: string,
  readStoreBy: EReadStoreBy = EReadStoreBy.ID,
  filterParams?: ProductFilterInputParams,
) => {
  return axios
    .get<Product[]>(getBuyerProductEndpoint(storeId), {
      params: {
        readStoreBy,
        ...filterParams,
      },
    })
    .then(res => res.data);
};

export const requestGetProductBySlug = async (storeSlug: string, productSlug: string) => {
  return axios
    .get<Product[]>(
      `${API_STORE}/api/store/buyer/store/${transformSlug(storeSlug)}/product/${transformSlug(productSlug)}`,
      {
        params: {
          readStoreBy: EReadEntityBy.SLUG,
          readProductBy: EReadEntityBy.SLUG,
        },
      },
    )
    .then(res => res.data);
};

export const requestSearchProducts = async (storeId: string, filterParams: ProductFilterInputParams) => {
  return axios.get<Product[]>(getBuyerProductEndpoint(storeId), { params: filterParams }).then(res => res.data);
};

export const requestDeleteProduct = async (storeId: string, productId: string) => {
  return axios
    .delete<ActionResponse>(`${API_STORE}/api/store/store/${storeId}/product/${productId}`)
    .then(res => res.data);
};

export const requestUpdateProduct = async (storeId: string, productId: string, updatedProduct: Partial<Product>) => {
  return axios
    .put<ActionResponse>(`${API_STORE}/api/store/store/${storeId}/product/${productId}`, updatedProduct)
    .then(res => res.data);
};

export const requestGetDeliveryProfiles = async (storeId: string) => {
  return axios.get<DeliveryProfile[]>(`${API_STORE}/api/store/store/${storeId}/delivery-profile`).then(res => res.data);
};

export const requestCreateDeliveryProfile = async (data: DeliveryProfile, storeId: string) => {
  return axios
    .post<DeliveryProfile>(`${API_STORE}/api/store/store/${storeId}/delivery-profile`, data)
    .then(res => res.data);
};

export const requestUploadProductImage = async (formData: FormData, storeId: string) => {
  return axios
    .post<UploadPhotoResponse>(`${API_STORE}/api/store/store/${storeId}/product/photo`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(res => res.data);
};

export const requestCreateProduct = async (data: Product, storeId: string) => {
  return axios.post<Product>(`${API_STORE}/api/store/store/${storeId}/product`, data).then(res => res.data);
};

export const requestReorderProduct = async (storeId: string, updatedProducts: Partial<Product>[]) => {
  return axios
    .put<ActionResponse>(`${API_STORE}/api/store/store/${storeId}/product/reorder`, updatedProducts)
    .then(res => res.data);
};

export const requestGetRecommendProducts = async (props: {
  storeId: string;
  productIds: string[];
  page?: number;
  pageSize?: number;
}) => {
  const { storeId, productIds, page, pageSize } = props;
  const newIds = new URLSearchParams();
  if (!isEmpty(productIds)) {
    map(productIds, e => newIds.append('ids', e));
  }

  return axios
    .get(`${API_STORE}/api/store/buyer/store/${storeId}/product/recommendation`, {
      params: {
        newIds,
        page: page,
        pageSize: pageSize,
      },
    })
    .then(res => res.data);
};
