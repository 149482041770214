/* eslint-disable @typescript-eslint/no-explicit-any */
import { ProductActions, IProductGeneralAction, IDeleteProductAction, IProductAction } from './ProductActions';
import {
  requestDeleteProduct,
  requestGetDeliveryProfiles,
  requestGetProductBySlug,
  requestGetProducts,
  requestGetRecommendProducts,
  requestReorderProduct,
  requestSearchProducts,
  requestUpdateProduct,
} from './ProductRepository';
import { DeliveryProfile, EReadStoreBy, Product, Store } from '../../types';
import { ProductFilterInputParams } from '../../types/product';
import { put, takeEvery, all, select } from 'redux-saga/effects';
import * as Effects from 'redux-saga/effects';
import { RootState } from '../../redux/reduxStore';
import { logError } from '../../utils/error';
import { FetchProductBySlugInput } from './Product.type';
import {
  toggleIsLoading,
  toggleAppStateFlag,
  toggleIsProductsLoading,
  toggleIsSearchProductsLoading,
} from 'redux/appState/AppStateActions';
import { requestPutCart } from 'redux/cart/CartRepository';

const call: any = Effects.call;

export const getStore = (state: RootState) => state.store;

function* fetchDisplayedProductsAsync(action: IProductGeneralAction<ProductFilterInputParams>) {
  try {
    yield put(toggleIsLoading(true));
    yield put(toggleIsSearchProductsLoading(true));
    const store: Partial<Store> = yield select(getStore);
    const products: Product[] = yield call(requestSearchProducts, store.id, {
      searchText: action.data.searchText,
      orderBy: action.data.orderBy,
      filter: action.data.filter,
      categoryId: action.data.categoryId,
    });
    yield put({ type: ProductActions.SET_DISPLAYED_PRODUCTS_DATA, data: products });
  } catch (error) {
    logError(error);
  } finally {
    yield put(toggleIsLoading(false));
    yield put(toggleIsSearchProductsLoading(false));
  }
}

function* watchFetchDisplayedProductsAsync() {
  yield takeEvery(ProductActions.SET_DISPLAYED_PRODUCTS_DATA_ASYNC, fetchDisplayedProductsAsync);
}

function* fetchProductsAsync(action: IProductGeneralAction<{ slug: string }>) {
  try {
    yield put(toggleIsProductsLoading(true));
    const slug = action.data.slug;
    const products: Product[] = yield call(requestGetProducts, slug, EReadStoreBy.SLUG);
    yield put({ type: ProductActions.SET_PRODUCTS_DATA, data: products });
  } finally {
    yield put(toggleIsProductsLoading(false));
  }
}

function* watchFetchProductsAsync() {
  yield takeEvery(ProductActions.FETCH_PRODUCT_INFO_ASYNC, fetchProductsAsync);
}

function* fetchProductBySlugAsync(action: IProductGeneralAction<FetchProductBySlugInput>) {
  const { storeSlug, productSlug } = action.data;
  const product: Product = yield call(requestGetProductBySlug, storeSlug, productSlug);
  yield put({ type: ProductActions.FETCH_SELECTED_PRODUCT, data: product });
}

function* watchFetchProductBySlugAsync() {
  yield takeEvery(ProductActions.FETCH_SELECTED_PRODUCT_ASYNC, fetchProductBySlugAsync);
}

function* deleteProductAsync(action: IDeleteProductAction) {
  const store: Partial<Store> = yield select(getStore);
  yield call(requestDeleteProduct, store?.id, action.data.productId);
  yield put({ type: ProductActions.DELETE_PRODUCT, data: action.data });
}

function* watchDeleteProductAsync() {
  yield takeEvery(ProductActions.DELETE_PRODUCT_ASYNC, deleteProductAsync);
}

function* updateProductAsync(action: IProductGeneralAction<Partial<Product>>) {
  const store: Partial<Store> = yield select(getStore);
  yield call(requestUpdateProduct, store?.id, action.data.id, action.data);
  yield put({ type: ProductActions.UPDATE_PRODUCT, data: action.data });
}

function* watchUpdateProductAsync() {
  yield takeEvery(ProductActions.UPDATE_PRODUCT_ASYNC, updateProductAsync);
}

function* reorderProductAsync(action: IProductGeneralAction<Partial<Product>[]>) {
  try {
    const store: Partial<Store> = yield select(getStore);
    yield call(requestReorderProduct, store?.id, action.data);
    yield put({ type: ProductActions.REORDER_PRODUCT, data: action.data });
  } catch (error) {
    logError(error);
  }
}

function* watchReorderProductAsync() {
  yield takeEvery(ProductActions.REORDER_PRODUCT_ASYNC, reorderProductAsync);
}

function* fetchDeliveryProfile() {
  try {
    const deliveryProfile: DeliveryProfile[] = yield call(requestGetDeliveryProfiles);
    yield put({ type: ProductActions.FETCH_DELIVERY_PROFILE, data: deliveryProfile });
  } catch (err) {
    logError(err);
  }
}

function* watchFetchDeliveryProfileAsync() {
  yield takeEvery(ProductActions.FETCH_DELIVERY_PROFILE_ASYNC, fetchDeliveryProfile);
}

function* fetchRecommendProductsAsync(action: IProductAction) {
  try {
    yield put({
      type: ProductActions.SET_FETCHING_RECOMMEND_LIST,
      data: true,
    });
    yield put(toggleAppStateFlag('isProductDetailPageLoading', true));
    const store: Partial<Store> = yield select(getStore);
    const res: Product[] = yield call(requestGetRecommendProducts, {
      storeId: store.id,
      productIds: action.data.ids,
      page: action.data.page,
      pageSize: action.data.pageSize,
    });
    yield put({
      type: ProductActions.SET_IS_OUT_OF_RECOMMEND_PRODUCT,
      data: res.length === 0,
    });
    yield put({
      type: ProductActions.GET_RECOMMENDATION_PRODUCTS,
      data: res,
    });
  } catch (error) {
    logError(error);
  } finally {
    yield put(toggleAppStateFlag('isProductDetailPageLoading', false));
    yield put({
      type: ProductActions.SET_FETCHING_RECOMMEND_LIST,
      data: false,
    });
  }
}

function* watchFetchRecommendProducts() {
  yield takeEvery(ProductActions.GET_RECOMMENDATION_PRODUCTS_ASYNC, fetchRecommendProductsAsync);
}
function* getFinalPriceOfCurrentProduct(action: IProductAction) {
  try {
    const store = yield select(getStore);
    const res = yield call(requestPutCart, store.id, action.data, true);
    yield put({
      type: ProductActions.SET_PRODUCT_DETAIL_TO_UPDATE,
      data: {
        unitPrice: res?.items[0]?.originalPrice,
        finalPrice: res?.subTotal,
      },
    });
  } catch (e) {
    logError(e);
  }
}

function* watchGetFinalPriceOfCurrentProduct() {
  yield takeEvery(ProductActions.GET_CURRENT_PRODUCT_DETAIL_PRICE_ASYNC, getFinalPriceOfCurrentProduct);
}
function* productSaga() {
  yield all([
    watchFetchDisplayedProductsAsync(),
    watchDeleteProductAsync(),
    watchUpdateProductAsync(),
    watchFetchProductsAsync(),
    watchReorderProductAsync(),
    watchFetchDeliveryProfileAsync(),
    watchFetchProductBySlugAsync(),
    watchFetchRecommendProducts(),
    watchGetFinalPriceOfCurrentProduct(),
  ]);
}

export default productSaga;
