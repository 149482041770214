import filter from 'lodash/filter';
import { FeeConfigMethod, PaymentMethod } from 'types/merchant';

export const maskFullPan = (fullPan: string) => {
  return fullPan.replace(/\S(?=.{4})/g, '*');
};

export const cardImages = [
  '/visa.svg',
  '/jcb.svg',
  '/amex.svg',
  '/mastercard.svg',
  '/promptpay.svg',
  '/kasikornbank.png',
  '/siambank.svg',
  '/kasibank.png',
  '/truemoney.svg',
  '/linepay.svg',
  '/wechatpay.svg',
];

export enum ESelectPaymentMethodTitle {
  DEBIT_CREDIT_CARD = 'Debit/Credit Card',
  MOBILE_BANKING = 'Mobile Banking',
  TRUE_MONEY_WALLET = 'True Money Wallet',
}
export const paymentMethodsList = [
  {
    title: 'Debit/Credit Card',
    images: filter(cardImages, (_, i) => i < 4),
    subTitle: 'Pay with Debit/Credit Card',
    key: FeeConfigMethod.openLoopCard,
    paymentMethod: ESelectPaymentMethodTitle.DEBIT_CREDIT_CARD,
  },
  {
    title: 'PromptPay QR',
    images: filter(cardImages, (_, i) => i === 4),
    paymentMethod: PaymentMethod.promptpay_qr,
    key: FeeConfigMethod.promptPayQr,
  },
  {
    title: 'QR Credit',
    images: null,
    paymentMethod: PaymentMethod.qr_credit,
    key: FeeConfigMethod.qrCredit,
  },
  {
    title: 'Mobile Banking',
    images: filter(cardImages, (_, i) => [5, 7].includes(i)),
    subTitle: 'Select Bank',
    paymentMethod: PaymentMethod.mobile_banking,
    key: FeeConfigMethod.mobileBanking,
  },
  {
    title: 'True Money Wallet',
    images: filter(cardImages, (_, i) => i === 8),
    subTitle: 'Pay with TrueMoney Wallet',
    paymentMethod: PaymentMethod.true_wallet,
    key: FeeConfigMethod.trueWallet,
  },
  {
    title: 'Rabbit LINE Pay',
    images: filter(cardImages, (_, i) => i === 9),
    paymentMethod: PaymentMethod.line_pay,
    key: FeeConfigMethod.linePay,
  },
  {
    title: 'WeChat Pay',
    images: filter(cardImages, (_, i) => i === 10),
    paymentMethod: PaymentMethod.wechat_pay,
    key: FeeConfigMethod.weChatPay,
  },
  {
    title: 'Bill Payment',
    images: null,
    paymentMethod: PaymentMethod.bill_payment,
    key: FeeConfigMethod.billPayment,
  },
];
