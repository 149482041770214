import { Store } from 'types';
import { BuyerAddress } from './auth';
import { DiscountAppliedResult, DiscountRuleType } from './discount';
import { PaymentMethod } from './merchant';

export interface IOrder {
  status?: EStatusOrder;
  items?: IItemOrder[];
  subTotal?: number;
  shippingFee?: number;
  total?: number;
  customerDetails?: ICustomerDetail;
  buyerDetails?: IBuyerDetail;
  paymentData?: IPaymentData;
  fulfillmentData?: IFulfillmentData;
  remarks?: string;
  storeId?: string;
  createdAt?: string;
  updatedAt?: string;
  orderNumber?: number;
  store?: Store;
  id?: string;
  discountedAmount?: number;
  currency?: string;
  freeShipType?: string;
  manualDiscountAmount?: number;
  discountCode?: string;
  discountRes?: DiscountAppliedResult;
  isPhoneNumberRegistered?: boolean;
  type?: EOrderType;
  customerId?: string;
  deliveryProfile?: IManualOrderDeliveryProfile;
  customDiscount?: IManualOrderCustomDiscount;
  buyerTab?: EOrderTab;
  shouldShowPaymentInput?: boolean;
}
export interface IManualOrderCustomDiscount {
  name?: string;
  rule: DiscountRuleType;
  value: number;
  amount?: number;
}
export interface IManualOrderDeliveryProfile {
  id?: string;
  amount?: number;
  enabled?: boolean;
  isSelfPickedUp?: boolean;
}
export enum EOrderType {
  MANUAL = 'manual',
}
interface IBuyerDetail {
  name: string;
  userId?: string;
}
export interface IItemOrder {
  productPhotoUrls: string[];
  productId: string;
  productName: string;
  selectedVariants: IVariantItem[];
  quantity: number;
  unitPrice: number;
  total: number;
  remarks: string;
  originalPrice: number;
  finalPrice: number;
}
export interface IVariantItem {
  appliedVariants: { name: string; option: string }[];
  addedPrice: number;
}

export interface ICustomerDetail {
  allowLineOrderNotification?: boolean;
  name?: string;
  address?: string;
  phoneNumber: string;
  lineId?: string;
  email?: string;
  isSelfPickedUp?: boolean;
  fullName?: string;
  shippingAddress?: BuyerAddress;
  noteForDriver?: string;
}

export interface IPaymentData {
  paymentRequestCount?: number;
  paymentRequestTime?: string;
  paymentTime: string;
  status?: EPaymentStatus;
  receiptUrl: string;
  transactionData?: TransactionDataDto;
  allowedToPay?: boolean;
}
export interface TransactionDataDto {
  transactionId: string;
  transactionStatus: EPaymentStatus;
  paymentMethod: PaymentMethod;
  bankName?: string;
  cardNumber?: string;
  phoneNumber?: string;
  merchantToken?: string;
  merchantPublicKey?: string;
  shouldSkipPaymentRequest?: boolean;
}
export enum EPaymentStatus {
  UNPAID = 'UNPAID',
  PAID = 'PAID',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
}

export enum EStatusOrder {
  NEW = 'NEW',
  ACCEPTED = 'ACCEPTED',
  COMPLETED = 'COMPLETED',
  REJECTED = 'REJECTED',
  PAYMENT_CONFIRMED = 'PAYMENT_CONFIRMED',
  CANCELLED = 'CANCELLED',
}

export interface IFulfillmentData {
  isFulfilledManually: boolean;
  courier: string;
  courierTrackingUrl: string | null;
  trackingNumber: string;
}

export interface IPaymentDetail {
  promptPay?: { promptPayNumber: string; enabled: boolean };
  bankAccount?: {
    bankName: string;
    accountNumber: string;
    accountName: string;
    enabled: boolean;
  };
}

export enum EOrderTab {
  TO_PAY = 'TO_PAY',
  TO_RECEIVE = 'TO_RECEIVE',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
}

export interface IUpdateManualOrderCustomerDetails {
  customerId?: string;
  customerDetails?: ICustomerDetail;
  isSelfPickedUp?: boolean;
}
