import { PaymentMethod } from './../types/merchant';
import router from 'next/router';
import { getCurrentDomainAndProtocol } from './url';

export const AMOUNT_RECOMMEND_PRODUCT_PER_LOAD = 48;
export const DESKTOP_VIEW_NUMBER_OF_PRODUCT_ITEMS_IN_ROW = 4;
export const REMARK_LIMIT_NUMBER_OF_CHARACTERS = 100;
export const DEFAULT_DATE_FORMAT_TO_USER = 'D/MM/YYYY';
export const DAYS_IN_WEEK = [
  { text: 'Mon', fullText: 'Monday', key: 'MON' },
  { text: 'Tue', fullText: 'Tuesday', key: 'TUE' },
  { text: 'Wed', fullText: 'Wednesday', key: 'WED' },
  { text: 'Thu', fullText: 'Thursday', key: 'THU' },
  { text: 'Fri', fullText: 'Friday', key: 'FRI' },
  { text: 'Sat', fullText: 'Saturday', key: 'SAT' },
  { text: 'Sun', fullText: 'Sunday', key: 'SUN' },
];
export const NUMBER_OF_MONTHS_IN_YEAR = 12;
export const EMAIL_TYPE_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const ISR_REVALIDATE_TIME_SECONDS = 300;

export const defaultPostCodeData = {
  postCodeData: {
    text: '',
    value: 0,
  },
  minDeliveryPrice: 0,
};
export const VISA_TYPE_REGEX = /^4[0-9]{6,}$/;
export const MASTERCARD_TYPE_REGEX =
  /^5[1-5][0-9]{14}$|^2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]{12}$/; // Link: https://www.w3schools.blog/credit-card-validation-javascript-js
export const AMERICAN_EXPRESS_TYPE_REGEX = /^3[47][0-9]{5,}$/;
export const JCB_TYPE_REGEX = /^(?:2131|1800|35[0-9]{3})[0-9]{3,}$/;
export const FORMAT_PRICE_REGEX = /(\d)(?=(\d{3})+(?!\d))/g; // NOSONAR

export const mobileBankingData = [
  {
    title: 'Kasikorn Bank',
    image: '/kasikornbank.png',
    bankName: 'Kasikornbank Public Company Limited',
    isMobileOnly: false,
  },
  {
    title: 'Siam Commercial Bank',
    image: '/siambank.png',
    bankName: 'Siam Commercial Bank Public Company Limited',
    isMobileOnly: true,
  },
  {
    title: 'Bank of Ayudhya',
    image: '/kasibank.png',
    bankName: 'Bank of Ayudhya Public Company Limited',
    isMobileOnly: false,
  },
];

export const gbResponseUrl = (slugStore?: string) => {
  if (!slugStore) {
    const { slug } = router.query;
    return `${getCurrentDomainAndProtocol()}/${slug}/checkout/completed`;
  } else {
    return `${getCurrentDomainAndProtocol()}/${slugStore}/checkout/completed`;
  }
};

export const requiredAddressFieldKeys = ['postalCode', 'province', 'district', 'country'];

export const listOfImageSizeAscending = [
  {
    width: 100,
  },
  {
    width: 150,
  },
  {
    width: 200,
  },
  {
    width: 250,
  },
  {
    width: 300,
  },
  {
    width: 400,
  },
  {
    width: 500,
  },
  {
    width: 600,
  },
  {
    width: 700,
  },
  {
    width: 800,
  },
  {
    width: 900,
  },
  {
    width: 1000,
  },
  {
    width: 1100,
  },
  {
    width: 1200,
  },
  {
    width: 1300,
  },
  {
    width: 1350,
  },
];

export const accountMenuItems = [
  {
    title: 'Account Details',
    icon: '/icons/account_detail.svg',
    href: '/account-details',
  },
  {
    title: 'Address Book',
    icon: '/icons/address_book.svg',
    href: '/address-book',
  },
  {
    title: 'My Orders',
    icon: '/icons/my_order.svg',
    href: '/my-orders',
  },
];
export const MAXIMUM_SAVED_ADDRESS = 20;

export const NUMBER_OF_ADDRESS_TO_COMPUTE_DISTANCE_AT_ONCE = 3;
export const COUNTRY_FLAG_URI = 'https://d3a2p8n0vkdvcj.cloudfront.net/prod/country-flags';

export const MAX_INITIAL_ORDER_ITEM_NUMBER = 3;

export const DEFER_LOADING_ANALYTICS_TIME_MILLIS = 3000;

export const DEFER_LOADING_YBUG_TIME_MILLIS = 5000;

export const DEFER_LOADING_SENTRY_TIME_MILLIS = 5000;

export const isServer = typeof window === 'undefined';

export const MINIMUM_AMOUNT_MOBILE_BANKING = 20;

export const MAX_SCROLL_UP_BUTTON_HEIGHT = 115;

export const MAX_DESKTOP_DISPLAY_BUSINESS_NAME_LENGTH = 40;

export const RULES_IN_APP_BROWSER_REGEX = ['WebView', '(iPhone|iPod|iPad)(?!.*Safari/)', 'Android.*(wv|.0.0.0)'];

export const HLS_FILE_TYPE = ['m3u8'];
export const MAP_ZOOM_LEVEL_RADIUS = 10000;

export const THAI_PHONE_NUMBER_PREFIX = '+66';

export const ALL_CATEGORIES_FILTER_OPTION = {
  name: 'All categories',
  localName: 'All categories',
  logoUrl:
    process.env.NODE_ENV !== 'production'
      ? 'https://d3a2p8n0vkdvcj.cloudfront.net/staging/store-category-photos/zaapi-store-all-cat-img.jpeg'
      : 'https://d3a2p8n0vkdvcj.cloudfront.net/prod/store-category-photos/zaapi-store-all-cat-img.jpeg',
  id: 'all-categories-filter',
  slug: 'allCategories',
};

export const PAGE_SIZE_TO_LOAD_MORE = 20;

export const KEYS_TO_OMIT_AT_PROFILE = ['id', 'isDefault', 'latestPurchaseOrCreated'];

export const MONTHS_IN_YEAR = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
// Default Thailand map center
export const DEFAULT_MAP_CENTER = {
  lat: 13.756331,
  lng: 100.501762,
};

export const countryThaiLand = {
  name: 'Thailand',
  dial_code: '+66',
  code: 'TH',
};
export const METHODS_HAVE_INSTRUCTION_STEPS = [
  PaymentMethod.bill_payment,
  PaymentMethod.promptpay_qr,
  PaymentMethod.qr_credit,
];
export const CARD_FIELD_LENGTH = {
  numberCard: [15, 16],
  securityCode: [3, 4],
};
export const listFields = [
  'detailedAddress',
  'detailedAddress2',
  'barangay',
  'district',
  'province',
  'postalCode',
  'subDistrict',
];
export const EXPIRE_COOKIE_TIME_IN_MINUTES = 5;
