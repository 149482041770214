/* eslint-disable @typescript-eslint/no-explicit-any */
import { IProductCart, Product } from '../../types';
import { ProductFilterInputParams } from '../../types/product';
import { FetchProductBySlugInput } from './Product.type';
export enum ProductActions {
  FETCH_PRODUCT_INFO_ASYNC = 'FETCH_PRODUCT_INFO_ASYNC',
  SET_IS_OUT_OF_RECOMMEND_PRODUCT = 'SET_IS_OUT_OF_RECOMMEND_PRODUCT',
  SET_PRODUCTS_DATA = 'SET_PRODUCTS_DATA',
  CLEAR_PRODUCT_DATA = 'CLEAR_PRODUCT_DATA',
  SET_DISPLAYED_PRODUCTS_DATA = 'SET_DISPLAYED_PRODUCTS_DATA',
  SET_DISPLAYED_PRODUCTS_DATA_ASYNC = 'SET_DISPLAYED_PRODUCTS_DATA_ASYNC',
  DELETE_PRODUCT = 'DELETE_PRODUCT',
  DELETE_PRODUCT_ASYNC = 'DELETE_PRODUCT_ASYNC',
  SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT',
  ADD_CART_PRODUCTS = 'ADD_CART_PRODUCTS',
  DELETE_CART_PRODUCTS = 'DELETE_CART_PRODUCTS',
  UPDATE_CART_PRODUCTS = 'UPDATE_CART_PRODUCTS',
  UPDATE_PRODUCT = 'UPDATE_PRODUCT',
  UPDATE_PRODUCT_ASYNC = 'UPDATE_PRODUCT_ASYNC',
  SET_PRODUCT_DISPLAY_FILTER = 'SET_PRODUCT_DISPLAY_FILTER',
  SET_PRODUCTS_DATA_ASYNC = 'SET_PRODUCTS_DATA_ASYNC',
  REORDER_PRODUCT_ASYNC = 'REORDER_PRODUCT_ASYNC',
  REORDER_PRODUCT = 'REORDER_PRODUCT',

  FETCH_DELIVERY_PROFILE_ASYNC = 'FETCH_DELIVERY_PROFILE_ASYNC',
  FETCH_DELIVERY_PROFILE = 'FETCH_DELIVERY_PROFILE',
  FETCH_SELECTED_PRODUCT_ASYNC = 'FETCH_SELECTED_PRODUCT_ASYNC',
  FETCH_SELECTED_PRODUCT = 'FETCH_SELECTED_PRODUCT',

  GET_RECOMMENDATION_PRODUCTS = 'GET_RECOMMENDATION_PRODUCTS',
  SET_FETCHING_RECOMMEND_LIST = 'SET_FETCHING_RECOMMEND_LIST',
  RESET_RECOMMENDED_LIST = 'RESET_RECOMMENDED_LIST',
  GET_RECOMMENDATION_PRODUCTS_ASYNC = 'GET_RECOMMENDATION_PRODUCTS_ASYNC',

  SET_PRODUCT_DETAIL_TO_UPDATE = 'SET_PRODUCT_DETAIL_TO_UPDATE',
  GET_CURRENT_PRODUCT_DETAIL_PRICE_ASYNC = 'GET_CURRENT_PRODUCT_DETAIL_PRICE_ASYNC',
  SET_INDEX_OF_PRODUCT_TO_UPDATE = 'SET_INDEX_OF_PRODUCT_TO_UPDATE',
}

export interface IUpdateProductAction {
  type: ProductActions;
  data: Partial<Product>;
}

export interface IClearProductDataAction {
  type: ProductActions;
}

export interface IProductAction {
  type: ProductActions;
  data: any;
}

export interface ISearchProductsAction {
  type: ProductActions;
  data: {
    storeId: string;
    filter: ProductFilterInputParams;
  };
}

export interface IDeleteProductAction {
  type: ProductActions;
  data: {
    productId: string;
  };
}

export interface IProductGeneralAction<T> {
  type: ProductActions;
  data: T;
}

export const setProductDataAsyncAction = (): IProductGeneralAction<void> => {
  return { type: ProductActions.SET_PRODUCTS_DATA_ASYNC, data: null };
};

export const resetRecommendProductList = () => {
  return { type: ProductActions.RESET_RECOMMENDED_LIST };
};

export const setDisplayedProductDataAction = (products: Product[]): IProductAction => {
  return { type: ProductActions.SET_DISPLAYED_PRODUCTS_DATA, data: products };
};

export const fetchProductInfoActionAsyncBySlug = (slug: string): IProductGeneralAction<{ slug: string }> => {
  return { type: ProductActions.FETCH_PRODUCT_INFO_ASYNC, data: { slug } };
};

export const fetchSelectedProductAsyncBySlug = (
  storeSlug: string,
  productSlug: string,
): IProductGeneralAction<FetchProductBySlugInput> => {
  return { type: ProductActions.FETCH_SELECTED_PRODUCT_ASYNC, data: { storeSlug, productSlug } };
};

export const clearProductDataAction = (): IClearProductDataAction => {
  return { type: ProductActions.CLEAR_PRODUCT_DATA };
};

export const searchProductsAsyncAction = (
  filterParams: ProductFilterInputParams,
): IProductGeneralAction<ProductFilterInputParams> => {
  return {
    type: ProductActions.SET_DISPLAYED_PRODUCTS_DATA_ASYNC,
    data: filterParams,
  };
};

export const deleteProductAsyncAction = (productId: string): IDeleteProductAction => {
  return { type: ProductActions.DELETE_PRODUCT_ASYNC, data: { productId } };
};

export const setSelectedProductAction = (product: Product): IProductGeneralAction<Product> => {
  return { type: ProductActions.SET_SELECTED_PRODUCT, data: product };
};

export const addCartProductAction = (product: Product): IProductGeneralAction<Product> => {
  return { type: ProductActions.ADD_CART_PRODUCTS, data: product };
};

export const deleteCartProductAction = (product: Product[]): IProductAction => {
  return { type: ProductActions.DELETE_CART_PRODUCTS, data: product };
};
export const updateCartProductAction = (product: Product[]): IProductAction => {
  return { type: ProductActions.UPDATE_CART_PRODUCTS, data: product };
};
export const updateProductAsyncAction = (updatedProduct: Partial<Product>): IProductGeneralAction<Partial<Product>> => {
  return { type: ProductActions.UPDATE_PRODUCT_ASYNC, data: updatedProduct };
};

export const reorderProductAsyncAction = (
  updatedProducts: Partial<Product>[],
): IProductGeneralAction<Partial<Product>[]> => {
  return { type: ProductActions.REORDER_PRODUCT_ASYNC, data: updatedProducts };
};

export const setProductDisplayFilterAction = (
  filterParams: ProductFilterInputParams,
): IProductGeneralAction<ProductFilterInputParams> => {
  return {
    type: ProductActions.SET_PRODUCT_DISPLAY_FILTER,
    data: filterParams,
  };
};

export interface IFetchDeliveryProfileAsync {
  type: ProductActions;
}

export const fetchDeliveryProfile = (): IFetchDeliveryProfileAsync => {
  return {
    type: ProductActions.FETCH_DELIVERY_PROFILE_ASYNC,
  };
};
export const getRecommendProducts = (ids: string[], page?: number, pageSize?: number): IProductAction => {
  return {
    type: ProductActions.GET_RECOMMENDATION_PRODUCTS_ASYNC,
    data: {
      ids,
      page,
      pageSize,
    },
  };
};
export const getCurrentProductDetailPriceAsync = (data: IProductCart): IProductAction => {
  return {
    type: ProductActions.GET_CURRENT_PRODUCT_DETAIL_PRICE_ASYNC,
    data,
  };
};
export const setProductDetailToUpdate = (data: Product): IProductAction => {
  return {
    type: ProductActions.SET_PRODUCT_DETAIL_TO_UPDATE,
    data,
  };
};
export const setIndexOfProductToUpdate = (data: number): IProductAction => {
  return {
    type: ProductActions.SET_INDEX_OF_PRODUCT_TO_UPDATE,
    data,
  };
};
