import includes from 'lodash/includes';
import keys from 'lodash/keys';
import filter from 'lodash/filter';
import find from 'lodash/find';
import { ScreenHeaderStyle } from 'types';
import {
  CardInfoDto,
  cardValidateForm,
  CartCheckoutDto,
  FeeConfig,
  FeeConfigMethod,
  IGBPayGetQR,
  PaymentMethod,
  PaymentMethodResponseType,
  SavedMethod,
  SavedPaymentMethodsDto,
} from 'types/merchant';
import {
  AMERICAN_EXPRESS_TYPE_REGEX,
  JCB_TYPE_REGEX,
  MASTERCARD_TYPE_REGEX,
  mobileBankingData,
  VISA_TYPE_REGEX,
  CARD_FIELD_LENGTH,
  NUMBER_OF_MONTHS_IN_YEAR,
} from './constants';
import { CountryCode } from 'types/phone';
import isEmpty from 'lodash/isEmpty';
import i18n from 'i18next';
import { EPaymentStatus } from 'types/order';
import { isPhoneNumberValidAsync } from './validateAsync';
import dayjs from 'dayjs';
import parseInt from 'lodash/parseInt';

export const isActiveFeeMethod = (key: string, feeConfig: FeeConfig) => {
  switch (key) {
    case ScreenHeaderStyle.DEBIT_CARD:
      return includes(keys(feeConfig), FeeConfigMethod.openLoopCard);
    case ScreenHeaderStyle.TRUE_MONEY_WALLET:
      return includes(keys(feeConfig), FeeConfigMethod.trueWallet);
    case ScreenHeaderStyle.PROPMT_PAY:
      return includes(keys(feeConfig), FeeConfigMethod.promptPayQr);
    case ScreenHeaderStyle.QR_CREDIT:
      return includes(keys(feeConfig), FeeConfigMethod.qrCredit);
    case ScreenHeaderStyle.BILL_PAYMENT:
      return includes(keys(feeConfig), FeeConfigMethod.billPayment);
    case ScreenHeaderStyle.RABBIT_LINE_PAY:
      return includes(keys(feeConfig), FeeConfigMethod.linePay);
    case ScreenHeaderStyle.MOBILE_BANKING:
      return includes(keys(feeConfig), FeeConfigMethod.mobileBanking);
    case ScreenHeaderStyle.WE_CHAT_PAY:
      return includes(keys(feeConfig), FeeConfigMethod.weChatPay);
  }
};
export const ListDebitPaymentMethods = [
  PaymentMethod.visa_domestic,
  PaymentMethod.visa_overseas,
  PaymentMethod.mastercard_domestic,
  PaymentMethod.mastercard_overseas,
  PaymentMethod.jcb_domestic,
  PaymentMethod.jcb_overseas,
  PaymentMethod.american_express,
];
export const getApiGbPayType = (type: string) => {
  const QrResponse = [
    PaymentMethod.promptpay_qr,
    PaymentMethod.qr_credit,
    PaymentMethod.line_pay,
    PaymentMethod.wechat_pay,
  ];
  if (includes(QrResponse, type)) return PaymentMethodResponseType.GET_QR_IMAGE;
  if (includes(ListDebitPaymentMethods, type)) return PaymentMethodResponseType.GET_DEBIT_CARD_RESPONSE;
  switch (type) {
    case PaymentMethod.mobile_banking:
      return PaymentMethodResponseType.GET_MOBILE_BANKING;
    case PaymentMethod.bill_payment:
      return PaymentMethodResponseType.GET_BARCODE;
    case PaymentMethod.true_wallet:
      return PaymentMethodResponseType.GET_TRUE_WALLET;

    default:
      break;
  }
};
export const convertOpenLoopCardMethod = (cardNumber: string) => {
  const number = cardNumber?.replaceAll(' ', '');
  if (number?.match(VISA_TYPE_REGEX)) return PaymentMethod.visa_domestic;
  if (number?.match(JCB_TYPE_REGEX)) return PaymentMethod.jcb_domestic;
  if (number?.match(AMERICAN_EXPRESS_TYPE_REGEX)) return PaymentMethod.american_express;
  if (number?.match(MASTERCARD_TYPE_REGEX)) return PaymentMethod.mastercard_domestic;
  return null;
};
export const generateImageForDebitPayment = (type: string) => {
  switch (type) {
    case PaymentMethod.visa_domestic:
    case PaymentMethod.visa_overseas:
      return '/visa.svg';
    case PaymentMethod.jcb_domestic:
    case PaymentMethod.jcb_overseas:
      return '/jcb.svg';
    case PaymentMethod.mastercard_domestic:
    case PaymentMethod.mastercard_overseas:
      return '/mastercard.svg';
    case PaymentMethod.american_express:
      return '/amex.svg';
    default:
      return '/visa.svg';
  }
};
export const convertToFullBankName = (bankName: string) => {
  const bank = find(mobileBankingData, ['title', bankName]);
  if (bank) {
    return bank.bankName;
  } else {
    return undefined;
  }
};
export const getBankCode = (bankName: string) => {
  if (bankName === 'Kasikornbank Public Company Limited') return '004';
  else if (bankName === 'Siam Commercial Bank Public Company Limited') return '014';
  else return '025';
};

export const transformPayloadDebit = (order: CartCheckoutDto, token: string) => {
  const obj: IGBPayGetQR = {
    amount: order.total.toFixed(2),
    referenceNo: order.transaction.id.toString(),
    backgroundUrl: order.transaction.backgroundUrl,
    detail: `Payment for orderId ${order.id}`,
    customerName: order.customerDetails.name,
    customerEmail: order.customerDetails.email || undefined,
    customerTelephone: order.customerDetails.phoneNumber,
    otp: 'Y',
    responseUrl: order.transaction.responseUrl,
    card: {
      token,
    },
  };
  return obj;
};
// eslint-disable-next-line max-lines-per-function
export const transformPaymentPayload = (data: CardInfoDto, order: CartCheckoutDto) => {
  const merchantPublicKey = order.transaction.merchantPublicKey;
  let payload: IGBPayGetQR = {
    amount: order.total.toFixed(2),
    referenceNo: order.transaction?.id || order.transaction?.transactionId,
    backgroundUrl: order.transaction.backgroundUrl,
    detail: `Payment for orderId ${order.id}`,
    customerName: order.customerDetails.name,
    customerEmail: order.customerDetails.email || undefined,
    customerTelephone: order.customerDetails.phoneNumber,
    merchantName: data.merchantName,
  };
  const responseMethod = getApiGbPayType(data.paymentMethod);
  switch (data.paymentMethod) {
    case PaymentMethod.wechat_pay:
      payload = {
        ...payload,
        publicKey: merchantPublicKey,
        checksum: order.transaction.checksum,
      };
      break;
    case PaymentMethod.qr_credit:
    case PaymentMethod.promptpay_qr:
    case PaymentMethod.bill_payment:
      payload = {
        ...payload,
        token: order.transaction.merchantToken,
      };
      break;
    case PaymentMethod.line_pay:
    case PaymentMethod.true_wallet:
      payload = {
        ...payload,
        publicKey: merchantPublicKey,
        responseUrl: order.transaction.responseUrl,
        checksum: order.transaction.checksum,
        customerTelephone: data.phoneNumber || order.customerDetails.phoneNumber,
      };
      break;
    case PaymentMethod.mobile_banking:
      payload = {
        ...payload,
        publicKey: merchantPublicKey,
        responseUrl: order.transaction.responseUrl,
        bankCode: getBankCode(data.bankName),
        checksum: order.transaction.checksum,
      };
      break;
  }
  return { payload, responseMethod };
};
export const transformPaymentMethodLink = (payment: string) => {
  switch (payment) {
    case PaymentMethod.promptpay_qr:
      return 'qrcode';
    case PaymentMethod.qr_credit:
      return 'qrcredit';
    case PaymentMethod.line_pay:
      return 'linepay';
    case PaymentMethod.wechat_pay:
      return 'wechat';
    default:
      break;
  }
};
const isAvailableSavedMethod = (method: string, feeConfig: FeeConfig) => {
  if (includes(ListDebitPaymentMethods, method)) {
    return includes(keys(feeConfig), FeeConfigMethod.openLoopCard);
  }
  switch (method) {
    case PaymentMethod.mobile_banking:
      return includes(keys(feeConfig), FeeConfigMethod.mobileBanking);
    case PaymentMethod.bill_payment:
      return includes(keys(feeConfig), FeeConfigMethod.billPayment);
    case PaymentMethod.true_wallet:
      return includes(keys(feeConfig), FeeConfigMethod.trueWallet);
    case PaymentMethod.qr_credit:
      return includes(keys(feeConfig), FeeConfigMethod.qrCredit);
    case PaymentMethod.promptpay_qr:
      return includes(keys(feeConfig), FeeConfigMethod.promptPayQr);
    case PaymentMethod.line_pay:
      return includes(keys(feeConfig), FeeConfigMethod.linePay);
    case PaymentMethod.wechat_pay:
      return includes(keys(feeConfig), FeeConfigMethod.weChatPay);
  }
};
export const availableSavedMethod = (methods: SavedMethod[], feeConfig: FeeConfig) => {
  return filter(methods, (o: SavedMethod) => isAvailableSavedMethod(o.paymentMethod, feeConfig));
};
export const isHasPaymentMethodToCheckout = async (method: SavedMethod, region?: CountryCode) => {
  if (!method?.paymentMethod) return false;
  if (method.paymentMethod === PaymentMethod.mobile_banking) {
    return method?.paymentInfo?.bankName ? true : false;
  }
  if (method.paymentMethod === PaymentMethod.true_wallet) {
    if (method?.paymentInfo?.phoneNumber) {
      return isPhoneNumberValidAsync(method?.paymentInfo?.phoneNumber, region);
    } else return false;
  }
  if (includes(ListDebitPaymentMethods, method.paymentMethod)) {
    const { paymentInfo } = method;
    if (!paymentInfo) return false;
    else {
      if (
        !paymentInfo.cardNumber ||
        !paymentInfo.expirationDate ||
        !paymentInfo.name ||
        (!paymentInfo?.token && !paymentInfo?.securityCode)
      ) {
        return false;
      }
      return true;
    }
  }
  return true;
};
export const generatePayloadForSavedDebit = (cardInfo: SavedMethod, token: string) => {
  const obj: SavedPaymentMethodsDto = {
    paymentMethod: cardInfo?.paymentMethod,
    name: cardInfo?.paymentInfo.name,
    cardNumber: cardInfo?.paymentInfo.cardNumber,
    expirationDate: cardInfo?.paymentInfo.expirationDate,
    token: token,
  };
  return obj;
};
export const listMethodCannotRefund = [
  PaymentMethod.promptpay_qr,
  PaymentMethod.mobile_banking,
  PaymentMethod.bill_payment,
  PaymentMethod.qr_credit,
  PaymentMethod.wechat_pay,
  PaymentMethod.true_wallet,
];
export const isAlreadySaved = (cardInfo: SavedMethod, savedMethods: SavedMethod[]) => {
  switch (cardInfo.paymentMethod) {
    case PaymentMethod.mobile_banking: {
      const savedMobileBankings = filter(
        savedMethods,
        (o: SavedMethod) =>
          o.paymentMethod === PaymentMethod.mobile_banking && o.paymentInfo.bankName === cardInfo.paymentInfo?.bankName,
      );
      return !isEmpty(savedMobileBankings);
    }
    case PaymentMethod.true_wallet: {
      const savedTrueWallet = filter(
        savedMethods,
        (o: SavedMethod) =>
          o.paymentMethod === PaymentMethod.true_wallet &&
          o.paymentInfo.phoneNumber === cardInfo.paymentInfo?.phoneNumber,
      );
      return !isEmpty(savedTrueWallet);
    }
    default:
      return false;
  }
};
export const getNumberFromString = (value: string) => {
  return value?.replace(/\D/g, '');
};
export const isValidCard = (card: SavedPaymentMethodsDto, form: cardValidateForm) => {
  const newForm: cardValidateForm = form;
  let isValid = true;
  const cardNumberLength = getNumberFromString(card.cardNumber)?.length;
  const cardSecurityCodeLength = card.securityCode?.replaceAll(' ', '')?.length;
  if (
    card.cardNumber &&
    (!includes(CARD_FIELD_LENGTH.numberCard, cardNumberLength) || !convertOpenLoopCardMethod(card.cardNumber))
  ) {
    newForm.cardNumber = i18n.t('Please enter a valid card number');
    isValid = false;
  }
  if (!card.name) {
    newForm.name = i18n.t('Cardholder name cannot be empty');
    isValid = false;
  }
  if (card.securityCode && !includes(CARD_FIELD_LENGTH.securityCode, cardSecurityCodeLength)) {
    newForm.securityCode = i18n.t('Invalid CVV');
    isValid = false;
  }
  if (card.expirationDate) {
    const startOfThisMonth = dayjs().startOf('M');
    const month = parseInt(card.expirationDate.split('/')[0]);
    const date = dayjs(`1/${card.expirationDate}`, 'D/MM/YY')
      .month(month - 1)
      .endOf('M');
    if (
      getNumberFromString(card.expirationDate)?.replace(/\D/g, '')?.length !== 4 ||
      !date.isValid() ||
      date.isBefore(startOfThisMonth) ||
      month > NUMBER_OF_MONTHS_IN_YEAR
    ) {
      newForm.expirationDate = i18n.t('Invalid Expiry Date');
      isValid = false;
    }
  }
  return { isValid, newForm };
};
export const isCancelledFetchPaymentStatus = (status = ''): boolean => {
  return includes([EPaymentStatus.PAID, EPaymentStatus.FAILED], status);
};
export const shouldRedirectPaymentToNewPage = (paymentMethod: PaymentMethod) =>
  [PaymentMethod.line_pay, PaymentMethod.mobile_banking].includes(paymentMethod);

export const shouldDisableMobileBanking = (cartPrice: number, method: string, minimumAmt: number) => {
  return cartPrice < minimumAmt && method === PaymentMethod.mobile_banking;
};
