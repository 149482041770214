import { RootState } from 'redux/reduxStore';

export const sGetProductsInCart = (state: RootState) => state.product.productsInCart;

export const sGetRecommendProducts = (state: RootState) => state.product.recommendProducts;
export const sGetIsFetchingRecommendList = (state: RootState) => state.product.isFetchingRecommendList;
export const sGetIsOutOfRecommendProduct = (state: RootState) => state.product.isOutOfRecommendProduct;

export const sGetSelectedProduct = (state: RootState) => state.product.selectedProduct;

export const sGetProductDetailsToUpdate = (state: RootState) => state.product.productDetailToUpdate;
export const sGetProductDetailPrice = (state: RootState) => state.product.productDetailToUpdate?.finalPrice;

export const sGetIndexProductToUpdate = (state: RootState) => state.product.indexOfProductToUpdate;

export const sGetDisplayedProducts = (state: RootState) => state.product.displayedProducts;

export const sGetProducts = (state: RootState) => state.product.products;

export const sGetSelectedProductMedia = (state: RootState) => state.product.selectedProduct?.media;
export const sGetSelectedProductPhotoUrls = (state: RootState) => state.product.selectedProduct?.photoUrls;
