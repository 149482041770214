import produce from 'immer';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import keysIn from 'lodash/keysIn';
import { AddressDto } from 'types/auth';
import {
  EOrderTab,
  EPaymentStatus,
  EStatusOrder,
  ICustomerDetail,
  IPaymentData,
  IOrder,
  EOrderType,
} from 'types/order';
import { MAX_INITIAL_ORDER_ITEM_NUMBER, METHODS_HAVE_INSTRUCTION_STEPS } from './constants';
import { PaymentMethod } from 'types/merchant';
import { ScreenHeaderStyle } from 'types';

export const isPaymentConfirmState = (status: EStatusOrder, paymentData: IPaymentData): boolean => {
  return status === EStatusOrder.ACCEPTED && paymentData.status === EPaymentStatus.PAID;
};

export const isPaymentConfirmed = (status: EStatusOrder, paymentData: IPaymentData): boolean => {
  return status === EStatusOrder.COMPLETED || isPaymentConfirmState(status, paymentData);
};

export const generateOrderStateBar = (): EOrderTab[] => {
  return [EOrderTab.TO_PAY, EOrderTab.TO_RECEIVE, EOrderTab.COMPLETED];
};
export const shouldShowReturnButton = (status: EStatusOrder) => {
  if (status === EStatusOrder.ACCEPTED || status === EStatusOrder.COMPLETED) {
    return false;
  } else return true;
};

export const getOrderItemEndNotation = (isShowAll: boolean, index: number) => {
  return !isShowAll && index === MAX_INITIAL_ORDER_ITEM_NUMBER - 1 ? '...' : '';
};

export const convertCustomerDetailToCheckout = (customerDetail: ICustomerDetail): AddressDto => {
  const generalInfo = {
    fullName: customerDetail?.fullName,
    phoneNumber: customerDetail?.phoneNumber,
    email: customerDetail?.email,
    noteForDriver: customerDetail?.noteForDriver,
  };
  return produce(generalInfo, draft => {
    forEach(keysIn(customerDetail?.shippingAddress), (o: string) => {
      draft[o] = get(customerDetail?.shippingAddress, [o]);
    });
  });
};
export const getOrderTitleState = (buyerTab: EOrderTab) => {
  switch (buyerTab) {
    case EOrderTab.TO_PAY:
      return 'To Pay';
    case EOrderTab.TO_RECEIVE:
      return 'To Receive';
    case EOrderTab.COMPLETED:
      return 'Completed';
    case EOrderTab.CANCELLED:
      return 'Cancelled\n';
    // Uncomment when refunded feature is implemented
    // case EOrderTab.CANCELLED:
    //   return 'Refunded\n';
    default:
      return '';
  }
};
export const isOrderInEndState = (type: EOrderTab) => [EOrderTab.COMPLETED, EOrderTab.CANCELLED].includes(type);

export const shouldGoToOrderCheckoutPage = (orderData: IOrder) => {
  if (orderData.type === EOrderType.MANUAL) {
    if (!orderData.customerDetails) {
      return true;
    } else {
      return isEmpty(orderData?.paymentData?.status) || orderData?.paymentData?.status === EPaymentStatus.UNPAID;
    }
  } else return false;
};

export const shouldRedirectBuyerToPaymentPage = (orderStatus: EStatusOrder, paymentData: IPaymentData) => {
  return (
    METHODS_HAVE_INSTRUCTION_STEPS.includes(paymentData?.transactionData?.paymentMethod) &&
    orderStatus === EStatusOrder.NEW &&
    paymentData?.status !== EPaymentStatus.PAID &&
    paymentData?.allowedToPay
  );
};

export const shouldGoToPayForManualOrder = (orderData: IOrder) => {
  const paymentStatus = orderData?.paymentData?.status;
  return (
    orderData.type === EOrderType.MANUAL &&
    [EPaymentStatus.UNPAID, EPaymentStatus.PENDING].includes(paymentStatus) &&
    !shouldFillPaymentForOrder(orderData?.paymentData)
  );
};

export const shouldFillPaymentForOrder = (paymentData: IPaymentData) => {
  const paymentMethod = paymentData?.transactionData?.paymentMethod;
  const paymentMethodNeedInfo = [
    PaymentMethod.visa_domestic,
    PaymentMethod.american_express,
    PaymentMethod.visa_overseas,
    PaymentMethod.jcb_domestic,
    PaymentMethod.jcb_overseas,
    PaymentMethod.mastercard_domestic,
    PaymentMethod.mastercard_overseas,
    PaymentMethod.true_wallet,
    PaymentMethod.mobile_banking,
  ];
  return paymentMethodNeedInfo.includes(paymentMethod);
};

export const getScreenHeaderStyleNameForPayment = (paymentData: IPaymentData) => {
  if (!shouldFillPaymentForOrder(paymentData)) return;
  const paymentMethod = paymentData?.transactionData?.paymentMethod;
  if (paymentMethod === PaymentMethod.mobile_banking) {
    return ScreenHeaderStyle.MOBILE_BANKING;
  }
  if (paymentMethod === PaymentMethod.true_wallet) {
    return ScreenHeaderStyle.TRUE_MONEY_WALLET;
  }
  return ScreenHeaderStyle.DEBIT_CARD;
};
